import * as React from "react"
import Layout from "../components/layout.js"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <Layout>
      <h1 className="center">404</h1>
      <p><Link to="/">Home Page</Link></p>
    </Layout>
  )
}

export default NotFoundPage
